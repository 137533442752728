<template>
    <div>
        <div class="">
            <label class="mr-1">
                <img src="../../../public/assets/images/icon/icon_update_image.svg" class="cursor-pointer" width="35" height="35">
                <input ref="inputImg" type="file" name="" class="d-none" accept=".jpeg,.jpg,.gif,.png" multiple="true" @change="changeImage($event)">
            </label>
            <!-- <label class="mr-1">
                <img src="../../../public/assets/images/icon/video-play.svg" class="cursor-pointer" width="35" height="35">
                <input type="file" name="" class="d-none" accept=".mp4,.ogx,.oga,.ogv,.ogg,.webm" multiple="true" @change="changeVideo($event)">
            </label> -->
            <label class="mr-1">
                <img src="../../../public/assets/images/icon/folder-open.svg" class="cursor-pointer" width="35" height="35">
                <input ref="inputFile" type="file" name="" class="d-none" accept=".pdf,.docx,.doc,.xlsx,.xls" multiple="true" @change="changeFile($event)">
            </label>
        </div>
    </div>
</template>
<script>
export default {

  name: 'TopicPicker',

  data () {
    return {
      topic_images: null,
      topic_videos: null,
      topic_files: null
    }
  },
  methods: {
    changeImage (event) {
      let self = this
      let _files = !!self.topic_images && !!self.topic_images.length ? self.topic_images : []
      const files = event.target.files
      if (files) {
        for (let i = 0; i < files.length; i++) {
          const item = files[i]
          if (item) {
            if (item.name.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) != null) {
              _files.push(item)
            } else {
              self.$toast.open({
                message: 'Không thể chọn tệp ' + item.name + ' do không hợp lệ',
                type: 'warning'
              })
            }
          }
        }
        self.$emit('changeImage', _files)
        this.$refs.inputImg.value = null
      }
    },
    changeVideo (event) {
      let self = this
      let _files = !!self.topic_videos && !!self.topic_videos.length ? self.topic_videos : []
      const files = event.target.files
      if (files) {
        for (let i = 0; i < files.length; i++) {
          const item = files[i]
          if (item) {
            if (item.name.toLowerCase().match(/\.(mp4|ogx|oga|ogv|ogg|webm)$/) != null) {
              _files.push(item)
            } else {
              self.$toast.open({
                message: 'Không thể chọn tệp ' + item.name + ' do không hợp lệ',
                type: 'warning'
              })
            }
          }
        }
      }
      self.$emit('changeVideo', _files)
    },
    changeFile (event) {
      let self = this
      let _files = !!self.topic_files && !!self.topic_files.length ? self.topic_files : []
      const files = event.target.files
      if (files) {
        for (let i = 0; i < files.length; i++) {
          const item = files[i]
          if (item) {
            if (item.name.toLowerCase().match(/\.(pdf|docx|doc|xlsx|xls)$/) != null) {
              _files.push(item)
            } else {
              self.$toast.open({
                message: 'Không thể chọn tệp ' + item.name + ' do không hợp lệ',
                type: 'warning'
              })
            }
          }
        }
      }
      self.$emit('changeFile', _files)
      this.$refs.inputFile.value = null
    }
  }
}
</script>
<style lang="css" scoped>
</style>