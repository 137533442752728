<template>
  <div>
    <el-dialog
      class="cs-dialog"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      append-to-body
    >
      <template slot="title">
        <h3 class="text-black uppercase">Kích hoạt H-Credit cho khách hàng</h3>
      </template>
      <div class="modal-body">
        <div class="mt-2 flex items-center gap-2">
          <div class="field font-medium">Tên khách hàng:</div>
          <span class="uppercase">{{ patientInfo.name }}</span>
        </div>
        <div class="mt-2 flex items-center gap-2">
          <div class="field font-medium">BHYT:</div>
          <span>{{ patientInfo.insurance_number }}</span>
        </div>
        <div class="mt-2 flex items-center gap-2">
          <div class="field font-medium">Số điện thoại đăng ký H-Credit</div>
          <el-input v-model="phoneNumber" placeholde="Nhập số điện thoại"></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer flex items-center gap-2">
        <el-button @click="handleClose" class="flex-1">Đóng</el-button>
        <el-button
          type="primary"
          :loading="isLoading"
          class="flex-1"
          @click="handleActiveAccount"
        >Kích hoạt</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
// import appUtils from '../../utils/appUtils'
import { mixinGetPersonInfo } from '@/utils/mixinGetPersonInfo'
import vSelect from 'vue-select'

export default {
  name: 'ModalActiveAccount',
  mixins: [mixinGetPersonInfo],
  components: { vSelect },
  data () {
    return {
      dialogVisible: false,
      isLoading: false,
      patientInfo: {},
      phoneNumber: ''
    }
  },
  computed: {
    //
  },
  watch: {
    //
  },
  created () {
    //
  },
  methods: {
    openModal (isShow, patientInfo) {
      this.dialogVisible = isShow
      if (!isShow) return
      if (patientInfo) {
        this.patientInfo = patientInfo
        this.phoneNumber = this.patientInfo.phone
      }
    },
    handleClose () {
      this.dialogVisible = false
    },
    handleActiveAccount () {
      this.$confirm(`Bạn có chắc chắn muốn kích hoạt tài khoản cho bệnh nhân <strong>${this.patientInfo?.name}</strong> không?`, 'Xác nhận', {
        dangerouslyUseHTMLString: true,
        cancelButtonText: this.$t('lbl_cancel'),
        confirmButtonText: this.$t('lbl_confirm')
      })
        .then(async () => {
          this.isLoading = true
          const params = {
            phone: this.phoneNumber,
            person_id: this.patientInfo?.id,
            org_id: this.$globalClinic?.org_id
          }
          const res = await this.$rf.getRequest('DoctorRequest').activeAccount(params)
          console.log('🚀 ~ .then ~ res:', res)
          if (res.data.id) {
            this.handleClose()
            this.$emit('activeSuccess')
          } else if (res.data.message === 'Số điện thoại này đã được đăng ký') {
            // this.handleClose()
            this.$message.error('Số điện thoại này đã được đăng ký')
          } else {
            this.$toast.open({
              message: 'Kích hoạt tài khoản thất bại, vui lòng thử lại',
              type: 'error'
            })
          }
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
  
<style lang="scss" scoped>
::v-deep {
  .el-dialog {
    width: 600px;
    background-color: #fcfcfd;
    .el-dialog__header {
      padding-bottom: 0;
    }
    .el-dialog__body {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .modal-body {
    border-top: 1px solid #dbdbdb;
    color: #000;
    padding: 1rem 0;
    // height: 100vh;
    // max-height: unset;
    .vs__search {
      font-size: 14px;
      line-height: 20px;
    }
    .vs__selected {
      font-size: 14px;
      line-height: 20px;
    }
    .vs__dropdown-toggle {
      background-color: #f3f9ff;
    }
    .el-input {
      width: fit-content;
      .el-input__inner {
        height: 36px;
        padding: 0 8px;
        font-weight: 400 !important;
      }
    }
  }
}
.field {
  width: 200px;
}
</style>