var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"g-grid-container",style:({
    left: _vm.rowLabelWidth,
    width: `${100-(this.rowLabelWidth).replace('%','')}%`
  })},_vm._l((_vm.allHours),function(hour,index){return _c('div',{key:index,class:{
      'g-grid-line': false,
      'g-grid-line-highlighted': _vm.highlightedHours.includes(hour) && hour!== 12,
      'g-grid-line-midday-highlighted': _vm.highlightedHours.includes(hour) && hour == 12
    }})}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }