<template>
  <div>
    <div
      class="modal fade"
      id="modalUploadPac"
      tabindex="-1"
      aria-labelledby="modalUploadTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-sm modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header border-0 bg-white mt-3 mb-0">
            <h5 class="ml-4 fs-18 fw-700 txt-black">{{$t('circle_feature.lbl_select_file')}}</h5>
            <img
              src="../../../public/assets/images/icon/close-gray.svg"
              height="25px"
              class="cursor-pointer mr-3 mb-2"
              data-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div class="modal-body">
            <div class="upload-btn mb-3" @click="openInputDoc" v-show="!file">
              <img class="mr-1" src="../../../public/assets/images/icon//u_upload.svg" />
              <span class="fs-14 txt-pri">{{$t('circle_feature.lbl_select_file')}}</span>
            </div>
            <div class="mb-3" v-if="file">
              <div class="d-flex w-100 justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  <div
                    class="mr-2 selected-file-icon"
                    style="
                      background-image: url('../../../public/assets/images/icon/pac-icon.png');
                    "
                  ></div>
                  <p class="fs-14 fw-600 mb-0 max-line-1 w-265px">{{ file.name }}</p>
                </div>
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="cursor-pointer"
                  @click="deleteSltDoct(i)"
                >
                  <path
                    d="M6.00012 18.4998L17.9999 6.5"
                    stroke="#292D32"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M17.9999 18.4998L6.00012 6.5"
                    stroke="#292D32"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div class="modal-footer border-0 justify-content-center mb-2">
            <button
              type="button"
              class="btn bg-pri bd-pri text-white upload-btn2 radius-10 w-100"
              @click="onUpload"
              :disabled="loading || !file"
            >
              <span class="spinner-border mr-2" role="status" v-if="loading">
                <span class="sr-only">Loading...</span>
              </span>
              {{ loading ? this.$t('circle_feature.lbl_uploading') : this.$t('circle_feature.lbl_upload') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <input
      type="file"
      ref="inputFilePac"
      accept=".zip, .rar"
      @change="onSelectDoc($event)"
      class="d-none"
    />
  </div>
</template>

<script>
export default {
  name: 'ModalUploadPacs',
  data () {
    return {
      loading: false,
      file: null
    }
  },
  methods: {
    deleteSltDoct () {
      if (!confirm(this.$t('circle_feature.lbl_confirm_del_msg'))) return
      this.file = null
    },
    onSelectDoc (e) {
      const files = e.target.files
      this.err_file = ''
      let errFile = ''
      if (files) {
        const f = files[0]
        if (
          f.name.split('.').pop() === 'zip' ||
          f.name.split('.').pop() === 'rar'
        ) {
          this.file = f
        } else {
          if (errFile) alert(this.$t('circle_feature.lbl_zip_required_msg'))
        }
      }
      this.$refs.inputFilePac.value = null
    },
    openInputDoc () {
      this.$refs.inputFilePac.click()
      this.$refs.inputFilePac.value = null
    },
    onUpload () {
      if (!this.file) return
      this.loading = true
      this.$emit('onUploadPac', this.file)
      setTimeout(() => {
        this.loading = false
        this.file = null
      }, 300)
    }
  }
}
</script>

<style scoped>
.upload-btn2 {
  padding: 18px 0px 18px 0px;
}
.upload-btn {
  width: 100%;
  border: 1px dashed #20419b;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  border-radius: 9px;
  cursor: pointer;
}
.selected-file-icon {
  width: 24px;
  height: 24px;
  background-size: contain;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
}
.w-265px {
  width: 100%;
  max-width: 265px;
}
</style>