<template>
  <div>
    <el-dialog
      class="cs-dialog"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      append-to-body
    >
      <template slot="title">
        <h3 class="text-black uppercase">Xuất báo cáo giao dịch</h3>
      </template>
      <div class="modal-body">
        <div class="mt-2 flex items-center gap-2">
          <div class="font-medium">Khoa/Phòng ban:</div>
          <span class="uppercase">{{ clinicName }}</span>
        </div>
        <el-form ref="form" :model="form" label-position="top" class="mt-3">
          <el-form-item label="Thời gian báo cáo">
            <el-date-picker
              v-model="form.date"
              type="daterange"
              range-separator="Đến"
              start-placeholder="Ngày bắt đầu"
              end-placeholder="Ngày kết thúc"
            ></el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer flex items-center gap-2">
        <!-- <el-button @click="handleClose" class="flex-1">Đóng</el-button> -->
        <el-button type="primary" :loading="isLoading" class="flex-1">Xuất báo cáo</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
// import appUtils from '../../utils/appUtils'
import { mixinGetPersonInfo } from '@/utils/mixinGetPersonInfo'
import vSelect from 'vue-select'

export default {
  name: 'ModalExportReport',
  mixins: [mixinGetPersonInfo],
  components: { vSelect },
  data () {
    const defaultForm = {
      date: ''
    }
    return {
      dialogVisible: false,
      isLoading: false,
      patientInfo: {},
      form: { ...defaultForm }
    }
  },
  computed: {
    availableNumber () {
      return this.form.availablePoints || 0
    },
    clinicName () {
      return this.$globalClinic?.name
    }
  },
  watch: {
    //
  },
  created () {
    //
  },
  methods: {
    openModal (isShow, patientInfo) {
      this.dialogVisible = isShow
      if (!isShow) return
      if (patientInfo) {
        this.patientInfo = patientInfo
        this.phoneNumber = this.patientInfo.phone
      }
      this.form.date = [new Date(), new Date()]
    },
    handleClose () {
      this.dialogVisible = false
      this.form.date = ''
    }
  }
}
</script>
        
      <style lang="scss" scoped>
::v-deep {
  .el-dialog {
    width: 600px;
    background-color: #fcfcfd;
    .el-dialog__header {
      padding-bottom: 0;
    }
    .el-dialog__body {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .modal-body {
    border-top: 1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb;
    color: #000;
    border-radius: unset;
    padding: 1rem 0;
    // height: 100vh;
    // max-height: unset;
    .vs__search {
      font-size: 14px;
      line-height: 20px;
    }
    .vs__selected {
      font-size: 14px;
      line-height: 20px;
    }
    .vs__dropdown-toggle {
      background-color: #f3f9ff;
    }
    .input-available {
      //   width: fit-content;
      .el-input__inner {
        background-color: #e9f4ff !important;
        color: #20409b !important;
      }
    }
    .el-input {
      .el-input__inner {
        height: 36px;
        padding: 0 8px;
      }
    }
    .el-select {
      width: 100%;
    }

    .el-form {
      .el-form-item {
        margin-bottom: 8px;
        .el-form-item__label {
          line-height: 20px;
          margin-bottom: 4px;
          padding-bottom: 0;
        }
      }
    }
  }
}
.field {
  width: 200px;
}
.color-pri {
  color: #20409b;
}
</style>