export default {

  'default': {
    primary: '#eeeeee',
    secondary: '#E0E0E0',
    ternary: '#F5F5F5',
    hoverHighlight: 'rgba(204, 216, 219, 0.5)',
    text: '#404040',
    background: 'white'
  },

  'creamy': {
    primary: '#ffe8d9',
    secondary: '#fcdcc5',
    ternary: '#fff6f0',
    hoverHighlight: 'rgba(230, 221, 202, 0.5)',
    text: '#542d05',
    background: 'white'
  },

  'crimson': {
    primary: '#a82039',
    secondary: '#c41238',
    ternary: '#db4f56',
    hoverHighlight: 'rgba(196, 141, 141, 0.5)',
    text: 'white',
    background: 'white'
  },

  'dark': {
    primary: '#404040',
    secondary: '#303030',
    ternary: '#353535',
    hoverHighlight: 'rgba(159, 160, 161, 0.5)',
    text: 'white',
    background: '#525252',
    toast: '#1f1f1f'
  },

  'flare': {
    primary: '#e08a38',
    secondary: '#e67912',
    ternary: '#5e5145',
    hoverHighlight: 'rgba(196, 141, 141, 0.5)',
    text: 'white',
    background: 'white'
  },

  'fuchsia': {
    primary: '#de1d5a',
    secondary: '#b50b41',
    ternary: '#ff7da6',
    hoverHighlight: 'rgba(196, 141, 141, 0.5)',
    text: 'white',
    background: 'white'
  },

  'grove': {
    primary: '#3d9960',
    secondary: '#288542',
    ternary: '#72b585',
    hoverHighlight: 'rgba(160, 219, 171, 0.5)',
    text: 'white',
    background: 'white'
  },

  'material-blue': {
    primary: '#0D47A1',
    secondary: '#1565C0',
    ternary: '#42a5f5',
    hoverHighlight: 'rgba(110, 165, 196, 0.5)',
    text: 'white',
    background: 'white'
  },

  'sky': {
    primary: '#b5e3ff',
    secondary: '#a1d6f7',
    ternary: '#d6f7ff',
    hoverHighlight: 'rgba(193, 202, 214, 0.5)',
    text: '#022c47',
    background: 'white'
  },

  'slumber': {
    primary: '#2c2e36',
    secondary: '#2f3447',
    ternary: '#35394d',
    hoverHighlight: 'rgba(179, 162, 127, 0.5)',
    text: '#ffe0b3',
    background: '#38383b',
    toast: '#1f1f1f'
  },

  'vue': {
    primary: '#258a5d',
    secondary: '#41B883',
    ternary: '#35495E',
    hoverHighlight: 'rgba(160, 219, 171, 0.5)',
    text: 'white',
    background: 'white'
  }
}
