<template>
    <div>
        <div class="d-flex flex-wrap" :class="!is_edit ? 'py-4' : ''" v-if="medias && medias.length">
            <div v-for="(item, index) in medias" :key="index" class="mb-1" :class="is_edit ? 'position-relative' : ''">
                <div v-if="isImage(item.name)" class="mr-2 thread-medias-img border bd-muted" :style="`background-image: url('${getLinkDownload(item.path)}');`" @click="onShowMedias(medias, index)">
                </div>
                <video v-if="isVideo(item.name)" class="mr-2 thread-medias-img border bd-muted" controls>
                    <source :src="getLinkDownload(item.path)" :type="item.type">
                    Your browser does not support the video tag.
                </video>
                <a v-if="isFile(item.name)" class="mr-2 thread-medias-img border bd-muted" target="_blank" :href="getLinkDownload(item.path)">
                    <p class="mb-0 robo-32-500 txt-pri mx-1"> {{item.name.split('.').pop()}}</p>
                </a>
                <img v-if="is_edit" class="position-absolute" src="../../../public/assets/images/icon/icon-close-circle.svg" style="cursor: pointer; top: -10px; right: 0;" @click="deleteMedia(index, item)">
            </div>
        </div>
        <CoolLightBox v-if="list_medias && list_medias.length" :items="getImagesFromEnries(list_medias)" :index="index_media" :useZoomBar="true" @close="index_media = null">
        </CoolLightBox>
    </div>
</template>
<script>
import appUtils from '../../utils/appUtils.js'
export default {

  name: 'TopicFileView',
  props: ['medias', 'is_edit'],
  data () {
    return {
      list_medias: null,
      index_media: null
    }
  },
  methods: {
    onShowMedias (medias, index) {
      let self = this
      self.list_medias = medias
      setTimeout(() => {
        self.index_media = index
      }, 100)
    },
    getLinkDownload (link) {
      return appUtils.getDocumentURL(link)
    },
    isImage (name) {
      return name.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) != null
    },
    isVideo (name) {
      return name.toLowerCase().match(/\.(mp4|ogx|oga|ogv|ogg|webm)$/) != null
    },
    isFile (name) {
      return name.toLowerCase().match(/\.(pdf|docx|doc|xlsx|xls)$/) != null
    },
    getImagesFromEnries (entries) {
      let results = []
      entries.forEach(file => {
        let file_name = file.name.split('.')
        results.push({
          title: file.content,
          src: appUtils.getDocumentURL(file.path),
          type: file_name ? file_name.pop().toLowerCase() : ''
        })
      })
      return results
    },
    deleteMedia (idx, item) {
      this.$emit('deleteMedia', idx, item)
    }
  }
}
</script>
<style lang="css" scoped>
.thread-medias-img {
    width: auto;
    height: 126px;
    min-width: 126px;
    border-radius: 12px;
    background-size: cover;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>