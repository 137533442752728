<template>
  <div
    class="g-gantt-row"
    ref="g-gantt-row"
    :style="{height: `${$parent.rowHeight}px`}"
    v-on="$listeners"
  >
    <div
      class="g-gantt-row-label"
      ref="g-gantt-row-label"
      @click="handleEditBedInfo()"
      @mouseover="onMouseoverLabel()"
      @mouseleave="onMouseleaveLabel()"
      :style="rowLabelStyle"
      style="
        border-bottom: 2px solid;
        border-right: 2px solid;
        border-left: 2px solid;
        border-color: rgb(255, 255, 255);
        "
    >
      <div>
        <div style="text-align: center;">
          <slot name="label">Giường {{label}}</slot>
        </div>
        <div>
          <slot name="price">
            <div style="font-weight: initial; text-align: center;">{{formatPrice(price)}}</div>
          </slot>
        </div>
      </div>
    </div>
    <div
      class="g-gantt-row-bars-container"
      ref="barContainer"
      :style="barsContainerStyle"
      @click="handleAssignPerson()"
      @dragover="onDragover($event)"
      @dragleave="onDragleave($event)"
      @drop="onDrop($event)"
      @mouseover="onMouseover()"
      @mouseleave="onMouseleave()"
    >
      <GGanttBar
        v-for="(bar, index) in bars"
        :key="`ganttastic_bar_${index}`"
        :bar="bar"
        ref="ganttBar"
        :bar-start="barStart"
        :bar-end="barEnd"
        :bar-container="barContainer"
        :all-bars-in-row="bars"
        @update-assign-bed="handleUpdateAssignBed"
      >
        <template #bar-label="{bar}">
          <slot name="bar-label" :bar="bar" />
        </template>
      </GGanttBar>
    </div>
  </div>
</template>

<script>
import GGanttBar from './GGanttBar.vue'
import moment from 'moment'
import appUtils from '../../../src/utils/appUtils'

export default {
  name: 'GGanttRow',

  components: {
    GGanttBar
  },

  props: {
    id: { type: Number, default: 0 },
    type_bed: { type: String, default: 0 },
    price: { type: String, default: '100.000' },
    label: { type: String, default: 'Row' },
    insurance_price: { type: String, default: '100.000' },
    description: { type: String, default: '' },
    roomId: { type: Number, default: 0 },
    bars: { type: Array, default: () => [] },
    barStart: { type: String, required: true }, // property name of the bar objects that represents the start datetime
    barEnd: { type: String, required: true }, // property name of the bar objects that represents the end datetime,
    highlightOnHover: Boolean
  },

  inject: [
    'ganttChartProps',
    'getThemeColors',
    'getHourCount',
    'getChartStart',
    'getChartEnd'
  ],

  data () {
    return {
      barContainer: {},
      appUtils
    }
  },

  computed: {
    rowLabelStyle () {
      return {
        width: this.ganttChartProps.rowLabelWidth,
        height: this.ganttChartProps.rowHeight,
        // background: this.$parent.themeColors.ternary,
        color: this.$parent.themeColors.text
      }
    },

    barsContainerStyle () {
      return {
        width: `${100 - this.ganttChartProps.rowLabelWidth.replace('%', '')}%`
      }
    }
  },

  mounted () {
    this.barContainer = this.$refs.barContainer.getBoundingClientRect()
    window.addEventListener('resize', this.onWindowResize)
  },

  methods: {
    formatPrice (price) {
      return appUtils.numberFormat(price, 'VNĐ')
    },
    onDragover (e) {
      e.preventDefault() // enables dropping content on row
      if (this.highlightOnHover) {
        // eslint-disable-next-line standard/computed-property-even-spacing
        this.$refs[
          'g-gantt-row'
        ].style.backgroundColor = this.getThemeColors().hoverHighlight
        // this.$refs['g-gantt-row-label'].style.backgroundColor = this.getThemeColors().hoverHighlight
      }
    },

    onDragleave () {
      this.$refs['g-gantt-row'].style.backgroundColor = null
    },
    handleEditBedInfo () {
      this.$emit('edit-bed', {
        bedObject: {
          id: this.id,
          type_bed: this.type_bed,
          price: this.price,
          name: this.label,
          roomId: this.roomId,
          description: this.description,
          insurance_price: this.insurance_price
        },
        show: true
      })
    },
    handleUpdateAssignBed (data) {
      const dataTranfer = {
        ...data,
        bedObject: {
          id: this.id,
          type_bed: this.type_bed,
          price: this.price,
          description: this.description,
          insurance_price: this.insurance_price,
          name: this.label,
          roomId: this.roomId
        }
      }
      this.$emit('update-assign-bed', dataTranfer)
    },
    handleAssignPerson () {
      const bed = {
        id: this.id,
        name: this.label,
        price: this.price,
        type_bed: this.type_bed,
        insurance_price: this.insurance_price,
        description: this.description,
        roomId: this.roomId
      }
      this.$emit('assign-person', { bedObject: bed, show: true })
    },
    onDrop (e) {
      let barContainer = this.$refs.barContainer.getBoundingClientRect()
      let xPos = e.clientX - barContainer.left
      let hourDiffFromStart = (xPos / barContainer.width) * this.getHourCount()
      let time = moment(this.getChartStart()).add(hourDiffFromStart, 'hours')
      let bar = this.bars.find(bar =>
        time.isBetween(bar[this.barStart], bar[this.barEnd])
      )
      this.$emit('drop', {
        event: e,
        bar,
        time: time.format('YYYY-MM-DD HH:mm:ss')
      })
    },
    onMouseoverLabel () {
      if (this.highlightOnHover) {
        // eslint-disable-next-line standard/computed-property-even-spacing
        this.$refs[
          'g-gantt-row-label'
        ].style.backgroundColor = this.getThemeColors().hoverHighlight
      }
    },
    onMouseleaveLabel () {
      this.$refs['g-gantt-row-label'].style.backgroundColor = null
    },
    onMouseover () {
      if (this.highlightOnHover) {
        // eslint-disable-next-line standard/computed-property-even-spacing
        this.$refs[
          'g-gantt-row'
        ].style.backgroundColor = this.getThemeColors().hoverHighlight
      }
    },

    onMouseleave () {
      this.$refs['g-gantt-row'].style.backgroundColor = null
      // this.$refs['g-gantt-row-label'].style.backgroundColor = null
    },

    onWindowResize () {
      // re-initialize the barContainer DOMRect variable, which will trigger re-rendering in the gantt bars
      this.barContainer = this.$refs.barContainer.getBoundingClientRect()
    }
  },

  watch: {
    'ganttChartProps.rowLabelWidth': function () {
      this.barContainer = this.$refs.barContainer.getBoundingClientRect()
    }
  }
}
</script>

<style scoped>
.g-gantt-row {
  display: flex;
  width: 100%;
  height: 40px;
  transition: background-color 0.2s;
}
.g-gantt-row-label {
  position: relative;
  z-index: 7;
  background-color: white;
}
.g-gantt-row > .g-gantt-row-label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  /* background: #E8E8E8; */
  color: #424242;
  font-size: 0.9em;
  z-index: 3;
  overflow: hidden;
  font-weight: bold;
}

.g-gantt-row > .g-gantt-row-bars-container {
  position: relative;
  /* border-top: 1px solid #eaeaea; */
  width: 70%;
  /* border-bottom: 1px solid #eaeaea; */
}
</style>
