<template>
  <div>
    <el-dialog
      class="cs-dialog"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      append-to-body
    >
      <template slot="title">
        <h3 class="text-black uppercase">HOÀN ĐIỂM H-CREDIT</h3>
      </template>
      <div class="modal-body">
        <div class="flex items-center gap-3">
          <div class="flex-1">
            <span class="font-medium">Tên khách hàng:</span>
            <span class="uppercase ml-1">{{ patientInfo.name }}</span>
          </div>
          <div class="flex-1">
            <span class="font-medium">Số điện thoại:</span>
            <span class="ml-1">{{ patientInfo.phone }}</span>
          </div>
        </div>
        <el-form ref="form" :model="form" label-position="top" class="mt-3">
          <el-form-item label="Mã phiếu thu cần hoàn điểm">
            <el-select v-model="form.receiptCode" placeholder="Chọn mã phiếu thu">
              <el-option
                v-for="item in listReceiptCode"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Số điểm hoàn trả cho bệnh nhân">
            <div class="flex items-center gap-2">
              <el-input v-model="form.availablePoints">
                <span slot="suffix" class="mr-1 text-xs">Điểm</span>
              </el-input>
              <span>~</span>
              <el-input v-model="availableNumber" disabled>
                <span slot="suffix" class="mr-1 text-xs">VND</span>
              </el-input>
            </div>
          </el-form-item>
          <el-form-item label="Số điểm hoàn trả cho bệnh viện">
            <div class="flex items-center gap-2">
              <el-input v-model="form.availablePoints">
                <span slot="suffix" class="mr-1 text-xs">Điểm</span>
              </el-input>
              <span>~</span>
              <el-input v-model="availableNumber" disabled>
                <span slot="suffix" class="mr-1 text-xs">VND</span>
              </el-input>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer flex items-center gap-2">
        <el-button @click="handleClose" class="flex-1">Đóng</el-button>
        <el-button type="primary" :loading="isLoading" class="flex-1">Xác nhận</el-button>
      </span>
    </el-dialog>
  </div>
</template>
      
      <script>
// import appUtils from '../../utils/appUtils'
import { mixinGetPersonInfo } from '@/utils/mixinGetPersonInfo'
import vSelect from 'vue-select'

export default {
  name: 'ModalReturnPoints',
  mixins: [mixinGetPersonInfo],
  components: { vSelect },
  data () {
    const defaultForm = {
      availablePoints: 0,
      totals: 0,
      receiptCode: ''
    }
    return {
      dialogVisible: false,
      isLoading: false,
      patientInfo: {},
      form: { ...defaultForm },
      listReceiptCode: []
    }
  },
  computed: {
    availableNumber () {
      return this.form.availablePoints || 0
    }
  },
  watch: {
    //
  },
  created () {
    //
  },
  methods: {
    openModal (isShow, patientInfo) {
      this.dialogVisible = isShow
      if (!isShow) return
      if (patientInfo) {
        this.patientInfo = patientInfo
        this.phoneNumber = this.patientInfo.phone
      }
    },
    handleClose () {
      this.dialogVisible = false
    }
  }
}
</script>
      
    <style lang="scss" scoped>
::v-deep {
  .el-dialog {
    width: 600px;
    background-color: #fcfcfd;
    .el-dialog__header {
      padding-bottom: 0;
    }
    .el-dialog__body {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .modal-body {
    border-top: 1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb;
    color: #000;
    border-radius: unset;
    padding: 1rem 0;
    // height: 100vh;
    // max-height: unset;
    .vs__search {
      font-size: 14px;
      line-height: 20px;
    }
    .vs__selected {
      font-size: 14px;
      line-height: 20px;
    }
    .vs__dropdown-toggle {
      background-color: #f3f9ff;
    }
    .input-available {
      //   width: fit-content;
      .el-input__inner {
        background-color: #e9f4ff !important;
        color: #20409b !important;
      }
    }
    .el-input {
      .el-input__inner {
        height: 36px;
        padding: 0 8px;
      }
    }
    .el-select {
      width: 100%;
    }

    .el-form {
      .el-form-item {
        margin-bottom: 8px;
        .el-form-item__label {
          line-height: 20px;
          margin-bottom: 4px;
          padding-bottom: 0;
        }
      }
    }
  }
}
.field {
  width: 200px;
}
.color-pri {
  color: #20409b;
}
</style>