<template>
  <div class="list-empty">
    <div class="txt-center">
      <img
        src="../../../public/assets/images/icon/empty.svg"
        alt=""
        class="w-100 mb-4 img-empty"
      />
      <p class="robo-20-500 txt-pri text-center">{{ title }}</p>
      <button
        v-if="isAddNewCta"
        class="btn bg-pri bd-pri text-white w-100 btn-add-new"
        @click="onAddNew"
      >
        <img
          src="../../../public/assets/images/icon/plus-circle-solid-white.svg"
          width="28"
          alt=""
          class="mr-2"
        />
        <span>
          {{ $t("obj_health_records.lbl_add_new") }}
        </span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    isAddNewCta: {
      type: Boolean,
      default: true
    }
  },
  name: 'ListEmpty',
  methods: {
    onAddNew () {
      this.$emit('onAddNew')
    }
  }
}
</script>

<style scoped>
.list-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 100px;
}
.img-empty {
  max-width: 250px;
}
.btn-add-new {
  height: 50px;
  border-radius: 0;
}
</style>
