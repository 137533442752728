var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"border-bottom":"2px solid #F5F5F5"},attrs:{"id":"g-timeaxis"}},[_c('div',{staticClass:"g-timeaxis-empty-space",staticStyle:{"position":"relative"},style:({width: _vm.rowLabelWidth})},[_c('div',{staticStyle:{"position":"absolute","font-weight":"bold","z-index":"1","top":"7px","right":"1rem"}},[_vm._v("Ngày")]),_c('div',{staticStyle:{"border-bottom":"2px solid #F5F5F5","width":"400px","transform":"rotate(25deg)","transform-origin":"left"}}),_c('div',{staticStyle:{"position":"absolute","font-weight":"bold","z-index":"1","left":"1rem","top":"3rem"}},[_vm._v("Tên giường")])]),_c('div',{staticClass:"g-timeaxis-days",staticStyle:{"background-color":"white","border-left":"2px dashed"},style:({width: `${100-_vm.rowLabelWidth.replace('%','')}%`})},_vm._l((_vm.axisDays),function(day,index){return _c('div',{key:day.text,staticClass:"g-timeaxis-day",staticStyle:{"border-right":"2px dashed","background-color":"white"},style:({
        width: day.widthPercentage+'%',
        // background: index%2===0 ? themeColors.primary : themeColors.secondary,
        color: _vm.themeColors.text,
      })},[_c('div',{staticStyle:{"background-color":"#AFBFFF","margin":"2px","border-radius":"3px"}},[_vm._v(_vm._s(_vm.dayFormatted(day)))]),_c('div',{staticStyle:{"justify-content":"center","align-items":"center"},style:({
          // background: index%2===0 ? themeColors.primary : themeColors.secondary,
          color: _vm.themeColors.text,
          })},[_c('div',{staticClass:"text-sm"},[_vm._v("12:00")])])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }