<template>
  <div id="g-timeaxis" style="border-bottom: 2px solid #F5F5F5;">
    <div class="g-timeaxis-empty-space" :style="{width: rowLabelWidth}" style="position: relative;">
      <div
        style="
      position: absolute;
      font-weight: bold;
      z-index: 1;
      top: 7px;
      right: 1rem"
      >Ngày</div>
      <div
        style="
        border-bottom: 2px solid #F5F5F5;
        width: 400px;
        transform: rotate(25deg);
        transform-origin: left;"
      ></div>
      <div
        style="
      position: absolute;
      font-weight: bold;
      z-index: 1;
      left: 1rem;
      top: 3rem;
      "
      >Tên giường</div>
    </div>
    <div
      class="g-timeaxis-days"
      :style="{width: `${100-rowLabelWidth.replace('%','')}%`}"
      style="background-color: white; border-left: 2px dashed;"
    >
      <div
        v-for="(day, index) in axisDays"
        :key="day.text"
        class="g-timeaxis-day"
        :style="{
          width: day.widthPercentage+'%',
          // background: index%2===0 ? themeColors.primary : themeColors.secondary,
          color: themeColors.text,
        }"
        style="border-right: 2px dashed; background-color: white;"
      >
        <div
          style="
          background-color: #AFBFFF;
          margin: 2px;   border-radius: 3px;"
        >{{dayFormatted(day)}}</div>
        <div
          :style="{
            // background: index%2===0 ? themeColors.primary : themeColors.secondary,
            color: themeColors.text,
            }"
          style="justify-content: center;align-items: center;"
        >
          <div class="text-sm">12:00</div>
          <!-- <div
            v-for="hour in day.ganttHours"
            :key="hour.fullDatetime"
            class="g-timeaxis-hour"
          >
            <span :style="{fontSize: hourFontSize}">{{hour.text}}</span>
            <div
              class="g-timeaxis-hour-pin"
              :style="{background: themeColors.text}"
            />
          </div>-->
        </div>
      </div>
    </div>
    <!-- <div id="g-timeaxis-marker"/> -->
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'GGanttTimeaxis',

  props: {
    chartStart: String,
    chartEnd: String,
    rowLabelWidth: String,
    timemarkerOffset: { type: Number, default: 0 },
    locale: String,
    themeColors: Object
  },

  data () {
    return {
      axisDays: [],
      hourCount: null,
      timemarker: null,
      hourFontSize: '11px',
      dayFormat: 'dddd, DD. MMMM'
    }
  },

  mounted () {
    // this.timemarker = document.querySelector('#g-timeaxis-marker')
    this.initAxisDaysAndHours()
    this.onWindowResize()
    window.addEventListener('resize', this.onWindowResize)
    window.addEventListener('mousemove', event => this.moveTimemarker(event))
    window.addEventListener('dragover', event => this.moveTimemarker(event))
  },

  methods: {
    initAxisDaysAndHours () {
      this.axisDays = []
      let start = moment(this.chartStart)
      let end = moment(this.chartEnd)
      // console.log(start)
      this.hourCount = Math.floor(end.diff(start, 'hour', true))
      while (start.isBefore(end)) {
        let hourCountOfDay =
          start.format('DD.MM.YYYY') === end.format('DD.MM.YYYY')
            ? end.hour()
            : 24 - start.hour()
        let widthPercentage = (hourCountOfDay / this.hourCount) * 100
        let endHour = start.day() === end.day() ? end.hour() - 1 : 23 // -1 because the last hour is not included e.g if chartEnd=04:00 the last interval we display is between 03 and 04
        this.axisDays.push(
          this.getAxisDayObject(start, widthPercentage, endHour)
        )
        start.add(1, 'day').hour(0)
      }
    },

    getAxisDayObject (datetime, widthPercentage, endHour) {
      let datetimeMoment = moment(datetime)
      let axisDayObject = {
        widthPercentage: widthPercentage,
        value: datetime.format('YYYY-MM-DD'),
        ganttHours: []
      }
      let startHour = datetimeMoment.hour()
      for (let i = 0; i <= endHour - startHour; i++) {
        let hour = {
          text: datetimeMoment.format('HH'),
          fullDatetime: datetimeMoment.format('DD.MM.YYYY HH:mm')
        }
        axisDayObject.ganttHours.push(hour)
        datetimeMoment.add(1, 'hour')
      }
      return axisDayObject
    },

    moveTimemarker (event) {
      // this.timemarker.style.left = (event.clientX - this.timemarkerOffset - this.horizontalAxisContainer.left) + 'px'
    },

    onWindowResize () {
      this.horizontalAxisContainer = document
        .querySelector('#g-timeaxis')
        .getBoundingClientRect()
      this.hourFontSize =
        Math.min(
          9.5,
          0.75 * (this.horizontalAxisContainer.width / this.hourCount)
        ) + 'px'
    },

    dayFormatted (day) {
      // do not display day text if the day is smaller than 12%
      const dayMoment = moment(day.value)
      return `${dayMoment.date()}/${dayMoment.month() + 1}/${dayMoment.year()}`
    }
  },

  watch: {
    chartStart () {
      this.initAxisDaysAndHours()
    },
    chartEnd () {
      this.initAxisDaysAndHours()
    }
  }
}
</script>

<style scoped>
#g-timeaxis,
.g-timeaxis-days,
.g-timeaxis-day,
.g-timeaxis-day > div {
  display: flex;
  overflow: hidden;
}

#g-timeaxis {
  position: sticky;
  top: 0;
  width: 100%;
  height: 8%;
  min-height: 75px;
  background: white;
  z-index: 4;
  /* box-shadow: 0px 1px 3px 2px rgba(50,50,50, 0.5); */
}

#g-timeaxis > .g-timeaxis-empty-space {
  width: 20%; /* this has to be as wide as .ganttRowTitle in VGanttastic.css */
  height: 100%;
  background: white;
}

#g-timeaxis > .g-timeaxis-days {
  position: relative;
  width: 80%;
  height: 100%;
}

.g-timeaxis-day {
  height: 100%;
  flex-direction: column;
  background: white;
}

.g-timeaxis-day:nth-child(odd) {
  background: white;
}

.g-timeaxis-day > div:nth-child(1) {
  /* day text */
  height: 50%;
  justify-content: space-around;
  font-weight: bold;
  align-items: center;
}

.g-timeaxis-day > div:nth-child(2) {
  /* hours of a day */
  align-items: flex-end;
  height: 50%;
  justify-content: space-between;
  background: white;
  padding-top: 2px;
  color: #212121;
}

.g-timeaxis-hour {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  opacity: 0.5;
  width: 100%;
}

.g-timeaxis-hour-pin {
  width: 1px;
  height: 8px;
}

#g-timeaxis-marker {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 3px;
  background: black;
}
</style>

