<template>
    <div class="d-flex flex-wrap">
        <div class="d-flex" v-if="topic_images && topic_images.length">
            <div class="position-relative" v-for="(item, index) in topic_images" :key="index">
                <div class="mr-2 thread-medias-img border bd-muted" :style="`background-image: url('${getBase64URL(item)}');`" @click="onShowMediaLocal(topic_images, index)">
                </div>
                <img class="position-absolute" src="../../../public/assets/images/icon/icon-close-circle.svg" style="cursor: pointer; top: -10px; right: 0;" @click="deleteMedia(index, 1)">
            </div>
        </div>
        <div class="d-flex" v-if="topic_videos && topic_videos.length">
            <div class="position-relative" v-for="(item, index) in topic_videos" :key="index">
                <video class="mr-2 thread-medias-img border bd-muted" controls>
                    <source :src="getBase64URL(item)" :type="item.type">
                    Your browser does not support the video tag.
                </video>
                <img class="position-absolute" src="../../../public/assets/images/icon/icon-close-circle.svg" style="cursor: pointer; top: -10px; right: 0;" @click="deleteMedia(index, 2)">
            </div>
        </div>
        <div class="d-flex" v-if="topic_files && topic_files.length">
            <div class="position-relative" v-for="(item, index) in topic_files" :key="index">
                <div class="mr-2 thread-medias-img border bd-muted">
                    <p class="mb-0 robo-32-500 txt-pri mx-1"> {{item.name.split('.').pop()}}</p>
                </div>
                <img class="position-absolute" src="../../../public/assets/images/icon/icon-close-circle.svg" style="cursor: pointer; top: -10px; right: 0;" @click="deleteMedia(index, 3)">
            </div>
        </div>
        <CoolLightBox :items="getImagesForCoolightLocal(list_medias_local)" :index="index_media_local" :useZoomBar="true" @close="index_media_local = null"></CoolLightBox>
    </div>
</template>
<script>
export default {

  name: 'TopicFilePreview',
  props: ['topic_images', 'topic_videos', 'topic_files'],
  data () {
    return {
      list_medias_local: null,
      index_media_local: null
    }
  },
  methods: {
    getBase64URL (file) {
      return window.URL.createObjectURL(file)
    },
    onShowMediaLocal (medias, index) {
      let self = this
      self.list_medias_local = medias
      self.index_media_local = index
    },
    getImagesForCoolightLocal (files) {
      let results = []
      if (files) {
        files.forEach(file => {
          results.push({
            title: this.txt_content || 'File đã chọn',
            src: this.getBase64URL(file),
            type: file.type
          })
        })
      }
      return results
    },
    deleteMedia (idx, type) {
      this.$emit('deleteMedia', idx, type)
    }
  }
}
</script>
<style lang="css" scoped>
.thread-medias-img {
    width: auto;
    height: 126px;
    min-width: 126px;
    border-radius: 12px;
    background-size: cover;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>